<template>
  <v-footer height="auto" absolute>
    <v-container pa-0 mb-0>
      <v-layout align-center row wrap>
        <v-flex
          xs12
          class="grey--text text--darken-2 caption text-xs-center"
          pt-1
          px-3
        >
          &copy; 2019 - {{ year }}
          <strong>Manchester Leathermen Limited</strong>.
        </v-flex>
        <v-flex
          class="grey--text text--darken-2 caption text-xs-center"
          pb-1
          px-3
        >
          Manchester Leathermen Limited is incorporated in England &amp; Wales,
          number 09986241. Registered office: 331 Walkden Road, Worsley,
          MANCHESTER M28 2RY. Read our
          <a href="#" style="color:#fff" class="font-weight-light" @click="dialog = true"
            >Privacy Statement</a
          >.
        </v-flex>

        <div class="text-xs-center">
          <v-dialog
            v-model="dialog"
            width="500"
            persistent
            scrollable
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <v-card>
              <v-card-title class="headline grey darken-4" primary-title clipped
                >Privacy Policy</v-card-title
              >
              <privacy-policy-content />

              <v-divider></v-divider>

              <v-card-actions class="grey darken-4">
                <v-spacer></v-spacer>
                <v-btn flat @click="dialog = false">I agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import PrivacyPolicyContent from "@/components/PrivacyPolicyContent";

export default {
  components: { PrivacyPolicyContent },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    year: function () {
      return new Date().getFullYear();
    },
  },
};
</script>
