import { firebase } from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import "@firebase/performance";
import "@firebase/analytics";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCh2GfvLKbvIZ67ejNpjU47ZCxOLiDVUm0",
  authDomain: "man-leathermen.firebaseapp.com",
  databaseURL: "https://man-leathermen.firebaseio.com",
  projectId: "man-leathermen",
  storageBucket: "man-leathermen.appspot.com",
  messagingSenderId: "899480654264",
  appId: "1:899480654264:web:e932be6f723b532b",
  measurementId: "G-SGZVBSZBLY"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const perf = firebase.performance();

const db = firebase.firestore();
const auth = firebase.auth();

// listen to auth status changes

var user = auth.onAuthStateChanged( user => {
  return user
})



export { db, auth, user, perf };