import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Meta from "vue-meta";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  base: process.env.BASE_URL,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // Rules for redirections
    { path: "/the-manchester-leathermen-team", redirect: "/team" },
    { path: "/author/chrismaster", redirect: "/team" },
    // { path: '/mlw', redirect: '/weekend' },

    {
      path: "/",
      name: "home",
      component: Home,
    },

    {
      path: "/events",
      name: "events",
      component: () => import("./views/OurEvents.vue"),
    },

    {
      path: "/membership",
      name: "membership",
      component: () => import("./views/BecomeMember.vue"),
    },

    {
      path: "/weekend",
      name: "weekend",
      component: () => import("./views/LeatherWeekend.vue"),
    },
    {
      path: "/weekend/events",
      name: "events",
      // component: () => import("./views/LeatherWeekend.vue"),
       component: () => import('./views/LeatherWeekendEvents.vue')
    },

    {
      path: "/weekend-events",
      redirect: "/weekend/events",
    },

    {
      path: "/weekend/tickets",
      name: "tickets",
      // component: () => import("./views/LeatherWeekend.vue"),
        component: () => import("./views/LeatherWeekendTickets.vue"),
    },

    {
      path: "/calendar",
      name: "calendar",
      component: () => import("./views/WhatsOn.vue"),
    },

    {
      path: "/shop",
      name: "shop",
      component: () => import("./views/OurShop.vue"),
    },

    {
      path: "/shop",
      name: "shop",
      component: () => import("./views/OurShop.vue"),
    },

    {
      path: "/team",
      name: "team",
      component: () => import("./views/OurTeam.vue"),
    },

    {
      path: "/account",
      name: "account",
      component: () => import("./views/UserAccount.vue"),
    },

    {
      path: "/members",
      name: "members",
      component: () => import("./views/Members.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("./views/Settings.vue"),
    },
    { path: "*", redirect: "/" },
  ],
});
