<template>
  <nav>
    <v-toolbar app py-0 my-0>
      <v-toolbar-side-icon
        class="hidden-lg-and-up"
        color="primary darken-2"
        @click="openLeftDrawer"
        ><v-icon>mdi-menu-open</v-icon></v-toolbar-side-icon
      >
      <v-spacer></v-spacer>
      <div class="center-screen">
        <router-link to="/"
          ><v-img
            max-width="253"
            text-xs-center
            text-xs-justify
            max-height="50"
            min-width="253"
            min-height="50"
            src="/assets/navbar_logo.png"
        /></router-link>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isDevelopment">
        <v-btn icon class="px-0 mx-0 hidden-lg-and-up" @click="openRightDrawer">
          <v-icon px-0 mx-0>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- Handling showing and hiding the drawers -->
    <left-drawer />
    <overlay />

    <right-drawer
      v-if="isDevelopment"
      @logintriggered="logintriggeractivator"
      @formtriggered="formtriggeractivator"
    />

    <v-layout py-0 my-0>
      <registration-form
        :signup="signUpFormOpened"
        @closedSignUpDialog="signUpFormOpened = false"
      />
      <user-login-form
        :loginDialog="loginDialogOpened"
        @closedloginDialog="loginDialogOpened = false"
      />
    </v-layout>
    <login-snackbar />
    <logout-snackbar />
    <new-user-snackbar />
    <banner />
    <registration-error-snackbar />
  </nav>
</template>

<script>
import LeftDrawer from "@/components/LeftDrawer";
import RightDrawer from "@/components/RightDrawer";
import Overlay from "@/components/Overlay";
import RegistrationForm from "@/components/RegistrationForm";
import UserLoginForm from "@/components/UserLoginForm";
import LoginSnackbar from "@/components/snackbars/loginSnackbar";
import LogoutSnackbar from "@/components/snackbars/logoutSnackbar";
import Banner from "@/components/snackbars/banner";
import NewUserSnackbar from "@/components/snackbars/newUserSnackbar";
import RegistrationErrorSnackbar from "@/components/snackbars/registrationErrorSnackbar";
export default {
  components: {
    LeftDrawer,
    RightDrawer,
    Overlay,
    RegistrationForm,
    UserLoginForm,
    LoginSnackbar,
    LogoutSnackbar,
    NewUserSnackbar,
    RegistrationErrorSnackbar,
    Banner,
  },
  methods: {
    formtriggeractivator() {
      this.signUpFormOpened = true;
    },
    logintriggeractivator() {
      this.loginDialogOpened = true;
    },
    openLeftDrawer() {
      this.$store.dispatch("openLeftDrawer");
    },
    openRightDrawer() {
      this.$store.dispatch("openRightDrawer");
    },
  },
  created: function() {
    if (this.$env === "development") {
      this.isDevelopment = true;
    } else {
      this.isDevelopment = false;
    }
  },

  data() {
    return {
      signUpFormOpened: false,
      loginDialogOpened: false,
      isDevelopment: false,
    };
  },
};
</script>

<style>
.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
