<template>
    <v-card-text ma-0 pa-0 class="text-xs-justify">
                <v-card-text
                  class="grey--text"
                >Manchester Leathermen takes the privacy of its members very seriously. We appreciate that not all of our members are “out” as leathermen and we will endeavour to never abuse the trust our members put in us.</v-card-text>

                <v-card-text
                  class="grey--text"
                >References in this statement to Manchester Leathermen or MLM should be taken to cover both the Club and Manchester Leathermen Ltd.</v-card-text>

                <v-card-text
                  class="title grey--text text-xs-left"
                >Collection, storage and use of personal information</v-card-text>

                <v-card-text class="grey--text">
                  We collect personal information when you become a member, sign up for our newsletter, buy a ticket for Manchester Leather Weekend, or become a volunteer with us. We store this securely, using commercial cloud services based in the EU or in the USA. Services based in the USA are covered by the
                  <a
                    href="https://en.wikipedia.org/wiki/EU%E2%80%93US_Privacy_Shield"
                    style="color:#fff"
                    class="font-weight-light"
                    target="_blank"
                    rel="noreferrer"
                  >EU-US Privacy Shield</a>.
                </v-card-text>

                <v-card-text
                  class="grey--text"
                >We are allowed to collect and use your personal information because you have formed a contract with us, because you have actively given consent, or for legitimate purposes connected to MLM’s business.</v-card-text>

                <v-card-text class="grey--text">
                  We only collect that information which we absolutely require to run MLM and our events.
                  <ul>
                    <li>Name</li>
                    <li>Contact Information</li>
                    <li>Date of birth (to ensure members are of legal adult age in the UK)</li>
                  </ul>
                </v-card-text>

                <v-card-text
                  class="grey--text"
                >We do not collect any sensitive or “special category” personal information. In particular, we do not collect information on your racial or ethnic origin; health; sexual orientation; or sex life.</v-card-text>

                <v-card-text class="title grey--text text-xs-left">How long we keep this information</v-card-text>

                <v-card-text class="grey--text">
                  <ul>
                    <li>Membership information is held for six months following termination of membership.</li>
                    <li>Newsletter subscription information is held for as long as you want to receive the newsletter. You may unsubscribe from the Newsletter at any time using the link in the email.</li>
                    <li>Information collected for other purposes is typically held for three years.</li>
                  </ul>
                </v-card-text>

                <v-card-text class="title grey--text text-xs-left">Third Party services</v-card-text>
                <v-card-text class="grey--text">
                  We use commercial cloud services to run MLM’s business. In particular, we draw your attention to the following services.
                  <ul>
                    <li>
                      <strong>Mailchimp</strong> is used for the Newsletter and has its own
                      <a
                        href="https://mailchimp.com/legal/privacy/"
                        style="color:#fff"
                        target="_blank"     
                        class="font-weight-light"                   
                        rel="noreferrer"
                      >privacy statement</a>.
                    </li>
                    <li>
                      <strong>TicketTailor.com</strong> is our ticketing partner for Manchester Leather Weekend and has its own
                      <a
                        href="https://www.tickettailor.com/privacy-policy/"
                        style="color:#fff"
                        target="_blank"
                        class="font-weight-light"
                        rel="noreferrer"
                      >privacy policy</a>.
                    </li>
                    <strong>Google Analytics</strong> allows us to collect anonymous data about our users to help improve our website performance and has its own
                    <a href="https://policies.google.com/privacy?hl=en-GB" target="_blank" style="color:#fff" class="font-weight-light" rel="noreferrer">Privacy Terms</a>.
                  </ul>
                </v-card-text>

                <v-card-text class="title grey--text text-xs-left">Payment Handling</v-card-text>

                <v-card-text
                  class="grey--text"
                >Payments to Manchester Leathermen are handled on our behalf by PayPal. We do not see, receive, or hold your payment card information.</v-card-text>

                <v-card-text class="title grey--text text-xs-left">How we will not use your information</v-card-text>

                <v-card-text
                  class="grey--text"
                >We will not use or disclose personal information of our members in any other way, except where required to by law enforcement agencies.</v-card-text>

                <v-card-text class="title grey--text text-xs-left">Using our website</v-card-text>
                <v-card-text class="grey--text">
                  You should note the following:
                  <ul>
                    <li>
                      Cookies may be placed on your computer to collect anonymised information on visitor behaviour. You can adjust the settings of your browser to not allow cookies, or delete them. See
                      <a
                        href="//www.aboutcookies.org.uk"
                        style="color:#fff"
                        target="_blank"
                        class="font-weight-light"
                        rel="noreferrer"
                      >www.aboutcookies.org.uk</a> for more information on cookies.
                    </li>
                    <li>We use Google Analytics on our website. We cannot prevent third party services such as Facebook from knowing you have visited our website.</li>
                  </ul>
                </v-card-text>

                <v-card-text class="title grey--text text-xs-left">Access to your information</v-card-text>

                <v-card-text class="grey--text">
                  You have a legal right of access to the personal information we hold about you. If you would like to have a copy, 
                  <a
                    href="mailto:secretary@manchesterleathermen.com"
                    style="color:#fff"
                    class="font-weight-light"
                    target="_blank"
                  >send us an email</a>. You can ask us to remove or correct information about you that you believe is inaccurate.
                </v-card-text>

                <v-card-text class="title grey--text text-xs-left">Photography at events</v-card-text>
                <v-card-text
                  class="grey--text"
                >Please be aware that we often have a volunteer photographer at our events and the photos will be published in our Facebook group and other social media channels we operate. Individuals will not be identified in these photos. If you not wish to appear in the photographs, please advise the photographer. We will remove any photo you appear in on request.</v-card-text>
              </v-card-text>
</template>

<script>
export default {
    
}
</script>
