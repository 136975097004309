<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0">
    <v-flex xs12 class="mb-0 mt-0">
      <v-card class="mt-0 pt-0">
        <v-card-title class="headline black justify-center py-2" primary-title
          >Welcome home, {{ username }}!</v-card-title
        >
        <v-card-text class="text-xs-center text-sm-left grey--text">
          <div>
            We welcome all members who are in our community, no matter how much
            gear you own or how experienced you are.
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-container grid-list-md px-0 mb-4 pb-4>
      <v-layout row mb-3 wrap fluid>
        <v-flex xs12 md4 class="mb-4" v-for="card in cards" :key="card.title">
          <v-card height="100%" class="flexcard">
            <v-card-title class="headline black justify-center py-1">
              {{ card.title }}
            </v-card-title>
            <v-parallax :src="card.image" height="150"> </v-parallax>
            <v-card-text
              class="text-xs-center text-sm-left grey--text"
              style="margin: 0 0 80px 0"
            >
              <div v-html="card.desc" />
            </v-card-text>
            <v-divider class="main-divider"></v-divider>
            <v-card-actions class="card-actions">
              <v-btn
                class="primary grey--text text--darken-4 ma-3"
                :to="card.btnlink"
                block
              >
                <v-icon left>{{ card.btnicon }}</v-icon>
                {{ card.btntext }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  computed: {
    username: function () {
      return this.$store.getters.userDetails.displayName;
    },
  },
  metaInfo: {
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en",
      link: [
        { rel: "canonical", href: "https://www.manchesterleathermen.com" },
      ],
      meta: [
        { itemprop: "name", content: "Manchester Leathermen" },
        {
          itemprop: "description",
          content:
            "Join Manchester's growing leather community! We host regular events, provide resources for leathermen, and provide a social network for leathermen, wherever they may be based.",
        },
      ],
    },
  },
  data: () => ({
    cards: [
      // {
      //   title: "MLW2023",
      //   image: "/assets/timeline.jpg",
      //   desc:
      //     "Manchester Leather Weekend is the biggest event in our calendar and we are looking forward to welcoming attendees from around the world for an event that is not to be missed.",
      //   btnlink: "/weekend",
      //   btnicon: "mdi-calendar-star",
      //   btntext: "Leather Weekend",
      // },
      {
        title: "Who we are",
        image: "/assets/main1.jpg",
        desc: "Manchester Leathermen is a community organisation in the North West of England who welcomes you, whatever kind of leatherman you are and wherever you are based.<br/><br/>You can support us (and get benefits) by becoming a member.",
        btnlink: "/membership",
        btnicon: "mdi-account-plus",
        btntext: "Become a member",
      },
      {
        title: "Our mission",
        image: "/assets/main2.jpg",
        desc: "We believe leather looks great and deserves to be worn. We have created a welcoming community for leathermen where friendship is encouraged.<br/><br/> We hold a monthly social on the first Saturday of each month.",
        btnlink: "/events",
        btnicon: "mdi-calendar-multiple",
        btntext: "View our events",
      },
      {
        title: "Our tradition",
        image: "/assets/main3.jpg",
        desc: "Manchester Leathermen was founded in 2015 following the merger of Manchester Super Chain MSC and Manchester Leather Men.<br/><br/>The Club has a proud history of local, national and international activity, and is the only British club to have hosted the ECMC Conference.",
        btnlink: "/team",
        btnicon: "mdi-account-group",
        btntext: "Meet our team",
      },
    ],
  }),
};
</script>

<style>
.flexcard {
  display: flex;
  flex-direction: column;
}

.main-divider {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
