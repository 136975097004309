<template>
  <div class="snackbar text-center">
    <v-snackbar
      v-model="snackbar"
      timeout=15000
      color="black"
      class="offer-banner"
      top
      multi-line
      shaped
      absolute
    >
      <span class="grey--text text--darken-4 font-weight-bold"> </span>
      <div>
        <v-btn
          to="/membership"
          class="primary grey--text text--darken-4 ma-3"
          @click="snackbar = false"
        >
          <v-icon left>mdi-account-plus</v-icon>
          Get 50% off of our Membership!
        </v-btn>
      </div>
      <v-btn flat color="white" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<!-- to enable, change snackbar to true --->
<script>
export default {
  data() {
    return {
      snackbar: false,
    };
  },
};
</script>
<style>
.offer-banner {
  min-width: 100%;
}
</style>
