<template>
  <!-- Left Drawer -->
  <v-navigation-drawer app v-model="visibility" width="217" class="{ color: black }">
    <v-card tile flat class="layout justify-center black my-3">
      <router-link to="/"><v-img  src="/assets/toplogo.jpg" max-width="217" max-height="115" position="center center" class="justify-center" /></router-link>
    </v-card>
    <v-divider></v-divider>
    <v-card tile >
      <v-list dense class="py-3 grey darken-4">
        <v-list-tile v-for="item in items" :key="item.title" router :to="item.link">
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card>
    <v-divider></v-divider>

    <v-card flat tile class="black">
      <v-layout column>
        <v-flex>
          <v-btn
            v-for="icon in icons"
            :key="icon.title"
            flat
            fab
            :href="icon.link"
            rel="noreferrer"
            target="_blank"
          >
            <v-icon large>{{ icon.title }}</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["leftControl"],
  computed: {
    visibility: {
      get: function() {
        return this.$store.getters.leftDrawerState;
      },
      set: function(newValue) {
        if(!newValue) {
          this.$store.dispatch('closeLeftDrawer');
        }
        
      }
      
    }
  },
  data() {
    return {
      items: [
        { title: "Home", icon: "mdi-view-dashboard", link: "/" },
        {
          title: "Become a Member",
          icon: "mdi-account-plus",
          link: "/membership"
        },
        /* Hidden link as shop is currently off */
        /* { title: "Our Shop", icon: "mdi-shopping", link: "/shop" }, */        
        {
          title: "Leather Weekend",
          icon: "mdi-calendar-star",
          link: "/weekend"
        },
        /*
        {
          title: "Mr Leather Europe",
          icon: "mdi-earth",
          link: "/mr-leather-europe"
        },
        */
        { title: "Our Events", icon: "mdi-calendar-multiple", link: "/events" },
        { title: "The Team", icon: "mdi-account-group", link: "/team" },
        {
          title: "What's on",
          icon: "mdi-calendar-question",
          link: "/calendar"
        },        
      ],
      icons: [
        {
          title: "mdi-facebook-box",
          link: "https://www.facebook.com/groups/manchesterleather/"
        },
        {
          title: "mdi-twitter-box",
          link: "https://twitter.com/manleathermen/"
        },
        {
          title: "mdi-instagram",
          link: "https://www.instagram.com/manchesterleathermen/"
        }
      ]
    };
  }
};
</script>