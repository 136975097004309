<template>
  <v-snackbar v-model="visibility" color="primary" :timeout="3500" top>
    <span class="grey--text text--darken-4 font-weight-bold">See you soon, leatherman!</span>
    <v-btn flat color="white" @click="close">
      <v-icon color="grey darken-4">mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  methods: {
    close: function() {
      this.$store.dispatch("deactivateLogoutSnackbar");
    }
  },
  computed: {
    visibility: {
      get: function() {
        return this.$store.getters.logoutSnackbarState;
      },
      set: function(newValue) {
        if (!newValue) {
          this.$store.dispatch("deactivateLogoutSnackbar");
        }
      }
    }   
  }
};
</script>

