<template>
  <div></div>
</template>

<script>
import { auth, db } from "@/fb";
// import { store } from "@/store";
export default {
  methods: {
    // Method to populate data on created hook
    tokenExists() {
      // Check whether user is authorised
      auth.onAuthStateChanged((user) => {
        // Empty object to erase user data on logout
        var empty = {};
        empty = {
          displayName: "leatherman",
          emailVerified: false,
          emailAddress: "",
        };
        // Push empty object to store on logout
        if (user == null) {
          this.$store.dispatch("userSignedOut");
          this.$store.dispatch("userToken", empty);
        } else {
          // Push auth data to store
          this.$store.dispatch("userSignedIn");
          this.$store.dispatch("userToken", user);

          // Push databse data to store
          var docRef = db.collection("users").doc(user.uid);
          var dbdata = function() {
            let promise = new Promise((resolve, reject) => {
              docRef
                .get()
                .then(function(doc) {
                  if (doc.exists) {
                    var populated = doc.data();
                    resolve(populated);
                  } else {
                    // doc.data() will be undefined in this case
                    reject();
                  }
                })
                .catch(function() {});
            });
            return promise;
          };
          this.$store.dispatch("userDBdata", dbdata());
        }
      });
    },
  },
  // Populate store with user data on Created hook
  created() {
    this.tokenExists();
  },
};
</script>
