<template>
  <div class="text-xs-center">
    <v-dialog v-model="displayed" max-width="600px">
      <v-card outlined>
        <v-card-title class="headline grey darken-3" primary-title clipped
          ><v-spacer></v-spacer
          ><v-img
            max-width="253"
            max-height="50"
            min-width="253"
            min-height="50"
            src="/assets/navbar_logo.png"/><v-spacer></v-spacer
        ></v-card-title>
        <v-img max-height="400px" src="/assets/main1.jpg">
          <div class="pa-3">
            <div class="px-3 py-4 overlay-background">
              <div class="overlay-content">
                <h2 class="">Dear Leathermen,</h2>
                <h4 class="pt-3">
                  You might notice our website isn't updated during these
                  unprecedented times.
                </h4>
                <h4 class="pt-3">
                  Please visit our Facebook page for the most updated
                  information, including our Zoom meetings hosted on Saturday
                  nights.
                </h4>
                <h4 class="pt-3">
                  Stay safe and we'll see you soon again.
                </h4>
                <h4 class="pt-3">
                  The Manchester Leatherman Team
                </h4>
              </div>
            </div>
          </div>
        </v-img>
        <v-card-actions class="grey darken-3">
          <v-btn
            large
            color="primary"
            href="https://www.facebook.com/groups/manchesterleather/"
          >
            <v-icon large>mdi-facebook-box</v-icon>
            &nbsp;&nbsp;&nbsp;Facebnook
          </v-btn>
          <v-spacer></v-spacer>
          <!-- Change to true to display the  main overlay -->
          <v-btn flat @click="displayed = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayed: false,
      icons: [
        {
          title: "mdi-facebook-box",
          link: "https://www.facebook.com/groups/manchesterleather/",
        },
        {
          title: "mdi-twitter-box",
          link: "https://twitter.com/manleathermen/",
        },
        {
          title: "mdi-instagram",
          link: "https://www.instagram.com/manchesterleathermen/",
        },
      ],
    };
  },
};
</script>
<style>
.overlay-background {
  background-color: rgba(66, 165, 245, 0.75);
  border-radius: 4px;
}
</style>
