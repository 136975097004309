import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import "./stylus/main.styl"
import VueAnalytics from 'vue-analytics'

// Do not show dev message in console in dev mode
Vue.config.productionTip = false;
import VueMeta from 'vue-meta'

// Set global env variable to purge dev vars from production code
Vue.prototype.$env = process.env.NODE_ENV

Vue.use(VueAnalytics, {
  id: 'G-SGZVBSZBLY',
  router
}, VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
