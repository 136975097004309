<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0">
    <div class="text-xs-center">
      <v-dialog
        v-model="visibility"
        width="500"
        scrollable
        lazy
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="membersApplication">
              <v-layout row wrap>
                <!-- Email -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="email"
                    label="Email address"
                    v-model="email"
                    prepend-icon="mdi-at"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-flex>

                <!-- Password -->
                <v-flex xs12>
                  <v-text-field
                    autocomplete="new-password"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-flex>

                <!-- Confirm password -->
                <v-flex xs12>
                  <v-text-field
                    autocomplete="new-password"
                    v-model="passwordToMatch"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, rules.match]"
                    :type="show2 ? 'text' : 'password'"
                    prepend-icon=" "
                    label="Confirm password"
                    class="input-group--focused"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-flex>

                <!-- First name -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="given-name"
                    label="First name"
                    v-model="firstName"
                    prepend-icon="mdi-account"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Last name -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="family-name"
                    label="Last name"
                    v-model="lastName"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Nickname -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="nickname"
                    label="Nickname"
                    :rules="[rules.required]"
                    v-model="nickName"
                    prepend-icon="mdi-account-heart"
                  ></v-text-field>
                </v-flex>

                <!-- Date of birth -->
                <v-flex>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      autocomplete="off"
                      color="white"
                      slot="activator"
                      label="Date of birth"
                      placeholder="18+ only"
                      prepend-icon="mdi-calendar"
                      :value="formattedDate"
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-date-picker
                      ahow-current="false"
                      first-day-of-week="1"
                      v-model="birthDate"
                      min="1920-01-01"
                      :max="restrictChildren"
                      @change="save"
                      ref="picker"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <!-- Street 1 -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="address-level1"
                    label="Address 1"
                    v-model="street1"
                    prepend-icon="mdi-home"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>

                <!-- Street 2 -->
                <v-flex xs12 px-1>
                  <v-text-field label="Address 2 (optional)" v-model="street2" prepend-icon=" "></v-text-field>
                </v-flex>

                <!-- City -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="address-level2"
                    label="City"
                    v-model="city"
                    prepend-icon="mdi-city"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Postcode -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="postal-code"
                    label="Postcode"
                    v-model="postcode"
                    prepend-icon=" "
                    :rules="[rules.required, rules.postcode]"
                  ></v-text-field>
                </v-flex>

                <!-- Phone number -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="tel-national"
                    label="Phone number (optional)"
                    v-model="phoneNumber"
                    prepend-icon="mdi-phone"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs-6 px-4 pb-3>
                <v-btn flat @click="signupClosed">Cancel</v-btn>
              </v-flex>
              <v-flex xs-6 class="text-xs-right px-4 pb-2">
                <v-btn
                  class="primary grey--text text--darken-4"
                  @click="submit"
                  :loading="loading"
                >Submit</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import format from "date-fns/format";
import { auth, db } from "@/fb";
export default {
  props: ["signup"],
  data() {
    return {
      // Set how date-picker is displayed
      date: null,
      menu: false,

      // Pop-up dialog control

      // Form fields
      email: "",
      password: "",
      passwordToMatch: "",
      firstName: "",
      lastName: "",
      nickName: "",
      birthDate: "",
      street1: "",
      street2: "",
      city: "",
      postcode: "",
      phoneNumber: "",

      // Password controls
      show1: false,
      show2: false,

      snackbar: false,

      // Form interaction
      loading: false,

      // Form validation
      rules: {
        required: value => !!value || "This field is required.",
        min: v => {
          if (v == undefined) {
            return false;
          } else {
            v.length >= 8 || "Please enter 8 or more characters.";
          }
        },
        match: () =>
          this.password === this.passwordToMatch || "Passwords don't match.",
        email: value => {
          if (value == undefined) {
            return false;
          } else {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Please enter a valid email address.";
          }
        },
        name: value => {
          if (value == undefined) {
            return false;
          } else {
            const regex = /^[a-zA-Z\- ]+$/;
            return (
              regex.test(value) || "English letters, spaces and dashes only."
            );
          }
        },
        postcode: value => {
          if (value == undefined) {
            return false;
          } else {
            value = value.replace(/\s/g, "");
            var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
            return regex.test(value) || "Please enter a valid postcode.";
          }
        }
      }
    };
  },

  computed: {
    // Format the date
    formattedDate() {
      return this.birthDate ? format(this.birthDate, "Do MMM YYYY") : "";
    },
    // Restrict age
    restrictChildren() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var maxYear = yyyy - 18;
      var end = maxYear + "-" + mm + "-" + dd;
      return end;
    },

    pickerStartDate() {
      var start = new Date();
      var mm = String(start.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = start.getFullYear() - 18;
      start = yyyy + "-" + mm;
      return start;
    },

    // Open registration form
    visibility: {
      get: function() {
        return this.$store.getters.regDialog;
      },
      set: function(newValue) {
        if (!newValue) {
          this.$store.dispatch("deactivateRegDialog");
          this.$refs.membersApplication.reset();
        }
      }
    },    
  },

  metaInfo: {
    titleTemplate: "%s | Join us"
  },

  // Start date pciker with year
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  methods: {
    // Submit form
    submit() {
      if (this.$refs.membersApplication.validate()) {
        if (this.street2 === undefined) {
          this.street2 = "";
        }
        if (this.phoneNumber === undefined) {
          this.phoneNumber = "";
        }
        this.loading = true;
        const memberForm = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          nickName: this.nickName,
          birthDate: format(this.birthDate, "Do MMM YYYY"),
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          postcode: this.postcode,
          phoneNumber: this.phoneNumber
        };

        // get the login info
        const email = this.email;
        const password = this.password;

        // sign up the user
        auth
          // pass username and password to Firebase
          .createUserWithEmailAndPassword(email, password)

          // Pass registration details
          .then(cred => {
            db.collection("users")
              .doc(cred.user.uid)
              .set({
                firstName: memberForm.firstName,
                lastName: memberForm.lastName,
                birthDate: memberForm.birthDate,
                street1: memberForm.street1,
                street2: memberForm.street2,
                postcode: memberForm.postcode,
                phoneNumber: memberForm.phoneNumber,
                city: memberForm.city,
                primaryTheme: '#BBDEFB',
              });
          })

          // Send verification email
          .then(() => {
            var user = auth.currentUser;
            user.sendEmailVerification();
          })

          .then(() => {
            var user = auth.currentUser;
            user.updateProfile({
              displayName: memberForm.nickName
            });
          })

          .then(() => {
            this.loading = false;
            this.$store.dispatch("deactivateRegDialog");
            this.$store.dispatch("activateNewUserSnackbar");
          })
          .catch(error => {
            this.$store.dispatch(
              "activateRegistrationErrorSnackbar",
              error.message
            );
            this.loading = false;
          });
      }
    },

    // Close the form by clicking cancel
    signupClosed() {
      this.$store.dispatch("deactivateRegDialog");
      this.$refs.membersApplication.reset();
    },

    // How date picker saves the value
    save(date) {
      this.$refs.menu.save(date);
    }
  }
};
</script>