<template>
  <!-- Right drawer -->
  <v-navigation-drawer app v-model="rVisibility" width="150" class="{ color: black }" right>
    <!-- Avatar -->
    <v-card tile flat class="black">
      <v-layout align-center justify-space-around wrap py-3>
        <v-avatar size="90" v-show="loggedIn">
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
        </v-avatar>
      </v-layout>
    </v-card>
    <v-divider></v-divider>
    <!-- Menu -->
    <v-card tile>
      <v-list dense class="py-3 grey darken-4">
        <v-list-tile
          v-for="menu in unauthorised"
          :key="menu.title"
          :to="menu.link"
          @click="menu.action"
          v-show="!loggedIn"
        >
          <v-list-tile-action>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-for="menu in authorised"
          :key="menu.title"
          :to="menu.link"
          @click="menu.action"
          v-show="loggedIn"
        >
          <v-list-tile-action>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card>
    <v-divider></v-divider>
    <!-- Bottom part -->
    <v-card flat tile class="black"></v-card>
  </v-navigation-drawer>
</template>
<script>
import { auth } from "@/fb";

export default {
  computed: {
    loggedIn: function() {
      return this.$store.getters.credentials;
    },
    rVisibility: {
      get: function() {
        return this.$store.getters.rightDrawerState;
      },
      set: function(newValue) {
        if(!newValue) {
          this.$store.dispatch('closeRightDrawer');
        }        
      }      
    },
  },
  props: ["rightControl"],
  methods: {
    void(){},
    registrationNeeded() {
      this.$store.dispatch('activateRegDialog');
    },

    loginDialogNeeded() {
      this.$store.dispatch('activateLoginDialog');
    },

    logoutUser() {
      auth.signOut().then(() => {
        this.$store.dispatch('activateLogoutSnackbar');
      });
    },    
  },
  data() {
    return {
      unauthorised: [        
        {
          title: "Log in",
          icon: "mdi-login",
          action: this.loginDialogNeeded
        },        
        {
          title: "Register",
          icon: "mdi-account-circle",
          action: this.registrationNeeded
        },
      ],
      authorised: [        
        {
          title: "Members",
          icon: "mdi-star",
          link: "/members",          
          action: this.void,     
        },
        {
          title: "Account",
          icon: "mdi-account",
          link: "/account",
          action: this.void,
        },   
        {
          title: "Settings",
          icon: "mdi-settings",
          link: "/settings",
          action: this.void,
        },
        {
          title: "Log out",
          icon: "mdi-logout",
          action: this.logoutUser
        },
      ]
    };
  }
};
</script>
