<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0">
    <div class="text-xs-center">
      <v-dialog v-model="visibility" width="500" scrollable>
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="loginDetails">
              <v-layout row wrap>
                <!-- Email -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="email"
                    label="Email address"
                    v-model="email"
                    prepend-icon="mdi-at"
                    :rules="[rules.required]"
                    :error="errorState"
                  ></v-text-field>
                </v-flex>

                <!-- Password -->
                <v-flex xs12>
                  <v-text-field
                    autocomplete="current-password"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    :error="errorState"
                    :error-messages="errorMessage"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-flex>
                <v-switch color="primary" :label="`Remember on this device`"></v-switch>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs-6 px-4 pb-3>
                <v-btn flat @click="loginClosed">Cancel</v-btn>
              </v-flex>
              <v-flex xs-6 class="text-xs-right px-4 pb-2">
                <v-btn
                  class="primary grey--text text--darken-4"
                  @click="submit"
                  :loading="loading"
                >Log in</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import { auth } from "@/fb";
export default {
  props: ["loginDialog"],
  data() {
    return {
      // Form fields
      email: "",
      password: "",

      // Password controls
      show1: false,
      show2: false,

      // Error state
      errorState: false,

      // Error Message
      errorMessage: "",

      // Form interaction
      loading: false,

      // Form validation
      rules: {
        required: value => !!value || "This field is required."
      }
    };
  },

  computed: {
    visibility: {
      get: function() {
        return this.$store.getters.loginDialog;
      },
      set: function(newValue) {
        if (!newValue) {
          this.$store.dispatch("deactivateLoginDialog");
          this.$refs.loginDetails.reset();
          this.errorState = false;
          this.errorMessage = "";
        }
      }
    }
  },

  methods: {
    // Submit form
    submit() {
      if (this.$refs.loginDetails.validate()) {
        this.loading = true;

        // get the login info
        const email = this.email;
        const password = this.password;

        // sign up the user
        auth
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            this.loading = false;
            this.$store.dispatch("deactivateLoginDialog");
            this.$refs.loginDetails.reset();
            this.$store.dispatch("activateLoginSnackbar");
          })
          .catch(error => {
            this.errorState = true;
            switch (error.code) {
              case "auth/invalid-email":
                this.errorMessage = "The email address seems incorrect.";
                break;
              case "auth/user-not-found":
                this.errorMessage =
                  "The email address or password is incorrect.";
                break;
              case "auth/wrong-password":
                this.errorMessage =
                  "The email address or password is incorrect.";
                break;
              default:
                this.errorMessage = error.message;
            }
            this.loading = false;
          });
      }
    },

    // Close the form by clicking cancel
    loginClosed() {
      this.$store.dispatch("deactivateLoginDialog");
      this.$refs.loginDetails.reset();
      this.errorState = false;
      this.errorMessage = "";
    }
  }
};
</script>