import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // STATE
  state: {
    // User related settings
    credentials: '',
    userDetails: {
      displayName: 'leatherman',
      primaryTheme: '#FF1D25',
      themeStyle: 'dark'
    },
    userDBdata: {},
    emailVerified: false,
    emailAddress: null,
    primaryTheme: '#FF1D25',

    // Drawers
    leftDrawerOpened: null,    
    rightDrawerOpened: null,


    // Snackbars
    loginSnackbarActive: false,    
    logoutSnackbarActive: false,    
    newUserSnackbarActive: false,
    registrationErrorSnackbarActive: false,
    registrationErrorMessage: "Sorry, the form couldn't be submitted",

    // Dialogs
    loginDialogDisplayed: false,
    regDialogDisplayed: false,
    memDialogDisplayed: false,
  },

  // MUTATIONS
  mutations: {

    // User related settings
    userSignedIn: function (state) {
      state.credentials = true;
    },
    userSignedOut: function (state) {
      state.credentials = false;
    },
    userToken: function (state, payload) {
      state.userDetails.displayName = payload.displayName;
      state.userDetails.emailVerified = payload.emailVerified;
      state.userDetails.emailAddress = payload.email;
    },
    togglePrimary(state, payload) {
      state.userDetails.primaryTheme = payload
      Vue.prototype.$vuetify.theme.primary = state.userDetails.primaryTheme;
    },
    userDBdata: function (state, payload) {
      state.userDBdata = payload;
    },

    // Drawers
    leftDrawerOpening: function (state) {
      state.leftDrawerOpened = true;
    },
    leftDrawerClosed: function (state) {
      state.leftDrawerOpened = null;
    },
    rightDrawerOpening: function (state) {
      state.rightDrawerOpened = true;
    },
    rightDrawerClosed: function (state) {
      state.rightDrawerOpened = null;
    },

    // Snackbars
    loginSnackbarActivating: function (state) {
      state.loginSnackbarActive = true;
    },
    loginSnackbarHiding: function (state) {
      state.loginSnackbarActive = false;
    },
    logoutSnackbarActivating: function (state) {
      state.logoutSnackbarActive = true;
    },
    logoutSnackbarHiding: function (state) {
      state.logoutSnackbarActive = false;
    },
    newUserSnackbarActivating: function (state) {
      state.newUserSnackbarActive = true;
    },
    newUserSnackbarHiding: function (state) {
      state.newUserSnackbarActive = false;
    },
    registrationErrorSnackbarActivating: function (state, payload) {      
      state.registrationErrorMessage = payload;
      state.registrationErrorSnackbarActive = true;      
    },
    registrationErrorSnackbarHiding: function (state) {
      state.registrationErrorMessage = null;
      state.registrationErrorSnackbarActive = false;      
    },

    // Dialogue Windows
    activatingLoginDialogue: function (state) {
      state.loginDialogDisplayed = true;
    },
    deactivatingLoginDialogue: function (state) {
      state.loginDialogDisplayed = false;
    },
    activatingRegDialogue: function (state) {
      state.regDialogDisplayed = true;
    },
    deactivatingRegDialogue: function (state) {
      state.regDialogDisplayed = false;
    },
    activatingMemDialogue: function (state) {
      state.memDialogDisplayed = true;
    },
    deactivatingMemDialogue: function (state) {
      state.memDialogDisplayed = false;
    },
  },

  // ACTIONS
  actions: {
    // User related settings
    userSignedOut: function (context) {
      context.commit("userSignedOut")
    },
    userSignedIn: function (context) {
      context.commit("userSignedIn")
    },
    userToken: function (context, payload) {
      context.commit('userToken', payload)
    },
    userDBdata: async function (context, payload) {
      context.commit('userDBdata', await payload)
    },    
    togglePrimary: function (context, payload) {
      context.commit('togglePrimary', payload)
    },

    // Drawers
    openLeftDrawer: function (context) {
      context.commit('leftDrawerOpening');
    },
    closeLeftDrawer: function (context) {
      context.commit('leftDrawerClosed');
    },
    openRightDrawer: function (context) {
      context.commit('rightDrawerOpening');
    },
    closeRightDrawer: function (context) {
      context.commit('rightDrawerClosed');
    },    

    // Snackbars
    activateLoginSnackbar: function (context) {
      context.commit('loginSnackbarActivating')
    },
    deactivateLoginSnackbar: function (context) {
      context.commit('loginSnackbarHiding')
    },
    activateLogoutSnackbar: function (context) {
      context.commit('logoutSnackbarActivating')
    },
    deactivateLogoutSnackbar: function (context) {
      context.commit('logoutSnackbarHiding')
    },
    activateNewUserSnackbar: function (context) {
      context.commit('newUserSnackbarActivating')
    },
    deactivateNewUserSnackbar: function (context) {
      context.commit('newUserSnackbarHiding')
    },
    activateRegistrationErrorSnackbar: function (context) {
      context.commit('registrationErrorSnackbarActivating')
    },
    deactivateRegistrationErrorSnackbar: function (context) {
      context.commit('registrationErrorSnackbarHiding')
    },
    resetError: function(context) {
      context.commit('resettingError')
    },

    // Dialogue windows
    activateLoginDialog: function (context) {
      context.commit('activatingLoginDialogue')
    },
    deactivateLoginDialog: function (context) {
      context.commit('deactivatingLoginDialogue')
    },

    activateRegDialog: function (context) {
      context.commit('activatingRegDialogue')
    },
    deactivateRegDialog: function (context) {
      context.commit('deactivatingRegDialogue')
    },
    activateMemDialog: function (context) {
      context.commit('activatingMemDialogue')
    },
    deactivateMemDialog: function (context) {
      context.commit('deactivatingMemDialogue')
    },
  },


  // GETTERS
  getters: {
    // User related settings
    credentials(state) {
      return state.credentials;
    },
    userDetails(state) {
      return state.userDetails;
    },
    userDBdata(state) {
      return state.userDBdata;
    },    
    primaryTheme(state) {
      return state.primaryTheme;
    },

    // Drawers
    leftDrawerState(state) {
      return state.leftDrawerOpened;
    },
    rightDrawerState(state) {
      return state.rightDrawerOpened;
    },

    // Snackbars
    loginSnackbarState(state){
      return state.loginSnackbarActive;
    },
    logoutSnackbarState(state){
      return state.logoutSnackbarActive;
    },
    newUserSnackbarState(state){
      return state.newUserSnackbarActive;
    },
    registrationErrorSnackbarState(state){
      return state.registrationErrorSnackbarActive
    },
    registrationErrorMessage(state){
      return state.registrationErrorMessage
    },

    // Dialogs
    loginDialog(state){
      return state.loginDialogDisplayed
    },
    regDialog(state){
      return state.regDialogDisplayed
    },
    memDialog(state){
      return state.memDialogDisplayed
    },
  },
})
