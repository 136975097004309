<template>
  <v-app dark>    
    <nav-bar />
    <v-content>
      <v-container>
        <router-view @accountCreated="snackbar = true"></router-view>
      </v-container>
      <Footer />
    </v-content>
    <presence />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";

import Footer from "@/components/Footer";
import Presence from "@/components/Presence";

export default {
  name: "App",
  components: { NavBar, Footer, Presence },
  metaInfo: {
    title: "Manchester Leathermen",
    canonical: "https://www.manchesterleathermen.com",
  },
};
</script>

<style>
a {
  text-decoration: none;
}

a.footer {
  text-decoration: underline;
}

.my-text {
  color: grey--text;
}

.headline,
.primary-title {
  text-transform: uppercase;
}
.v-btn {
  font-family: "AgencyFB-Black", Arial, Helvetica, sans-serif;
}

.event-title {
  background-color: rgba(100, 11, 15, 0.7);
}

.event-title-bottom {
  background-color: rgba(100, 11, 15, 0.45);
  width: 100%;
}

.contact-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
